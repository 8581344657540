import React from "react"
import { Grid } from "../../../../../components/general/Grid/Grid"
import { Section } from "../../../../../components/general/Section/Section"
import { TypographyAnimation } from "../../../../../components/general/TypographyAnimation/TypographyAnimation"
import {
  Color,
  FontFamily,
  YellowGridTheme,
} from "../../../../../data/style/variables"
import arrowSvg from "../../../../../images/SVG/Arrow 8 (1).svg"
import salesSvg from "../../../../../images/SVG/Frame 82 (1).svg"
import truckSvg from "../../../../../images/SVG/Frame 83 (2).svg"
import productionSvg from "../../../../../images/SVG/Frame 85.svg"
import { Bold } from "../../../../general/Bold/Bold"
import {
  Paragraph,
  TitleH2,
  TitleH3,
  Typography,
  TypographyTypes,
} from "../../../../general/Typography/Typography"
import {
  ArrowElement,
  ContainerElement,
  WorkflowIndexContainer,
  WorkflowItemContainer,
  WorkflowItemImageElement,
} from "./styled"

export const Departments: React.FC = () => {
  return (
    <ContainerElement>
      <Grid
        gridElementProps={{
          baseHeight: "auto",
          ...YellowGridTheme,
          detailTextColor: Color.DarkGray,
          spaced: true,
          noPaddingBottom: true,
        }}
        title={
          <TitleH2 color={Color.Black}>
            Een vloeiend verloop tussen uw{" "}
            <Bold background={Color.Black}>afdelingen</Bold>.
          </TitleH2>
        }
      >
        <Section>
          <WorkflowItemContainer>
            <TypographyAnimation>
              <TitleH3 color={Color.Black}>Verkoop en orderintake.</TitleH3>
              <Paragraph color={Color.Black}>
                Is uw offerte (in het CRM) een opdracht geworden dan staat de
                opdracht met één druk op de knop in het ERP. Uiteraard kan dit
                ook zonder gebruikmaking van een CRM. Flexibiliteit staat
                voorop, u kunt tot het moment van levering nog wijzigingen in de
                opdracht aanbrengen. Duidelijke overzichten geven u informatie
                over o.a. de verkoopwaarde per dag.
              </Paragraph>
              <WorkflowIndexContainer>
                <Typography
                  fontFamily={FontFamily.RobotoMono}
                  titleType={TypographyTypes.span}
                  color={Color.Black}
                  fontSize={"2rem"}
                >
                  01
                </Typography>
              </WorkflowIndexContainer>
              <ArrowElement src={arrowSvg} />
              <WorkflowItemImageElement src={salesSvg} />
            </TypographyAnimation>
          </WorkflowItemContainer>
        </Section>
        <Section>
          <WorkflowItemContainer>
            <TypographyAnimation index={1}>
              <TitleH3 color={Color.Black}>Inkoop en productie.</TitleH3>
              <Paragraph color={Color.Black}>
                Tijdens het productieproces voorziet het ERP u van duidelijke
                informatie over de voortgang. KPI’s zoals productiewaarde per
                dag, beschikbaar personeel etc. kunt u real-time opvragen en
                delen met uw collega’s. Het gehele inkooptraject kunt u
                nauwgezet volgen en toeleveranciers op o.a. hun
                leverbetrouwbaarheid monitoren.
              </Paragraph>
              <WorkflowIndexContainer>
                <Typography
                  fontFamily={FontFamily.RobotoMono}
                  titleType={TypographyTypes.span}
                  color={Color.Black}
                  fontSize={"2rem"}
                >
                  02
                </Typography>
              </WorkflowIndexContainer>
              <ArrowElement src={arrowSvg} />
              <WorkflowItemImageElement src={productionSvg} />
            </TypographyAnimation>
          </WorkflowItemContainer>
        </Section>
        <Section>
          <WorkflowItemContainer>
            <TypographyAnimation index={2}>
              <TitleH3 color={Color.Black}>
                Facturering en bedrijfsvoering.
              </TitleH3>
              <Paragraph color={Color.Black}>
                Personeelsregistratie en personeelsproductieplanning zijn
                flexibel geïmplementeerd in het ERP. In een koppeling met een
                extern salarisverwerkingsbedrijf is voorzien. Monitor eenvoudig
                en nauwgezet uw productiecapaciteit zodat u tijdig kunt
                bijsturen in het geval van bijvoorbeeld ziek personeel.
              </Paragraph>
              <WorkflowIndexContainer>
                <Typography
                  fontFamily={FontFamily.RobotoMono}
                  titleType={TypographyTypes.span}
                  color={Color.Black}
                  fontSize={"2rem"}
                >
                  03
                </Typography>
              </WorkflowIndexContainer>
              {/* This emtpy div is for the animation, without it the WorkflowItemImageElement will animate to soon. */}
              <div></div>
              <WorkflowItemImageElement src={truckSvg} />
            </TypographyAnimation>
          </WorkflowItemContainer>
        </Section>
      </Grid>
    </ContainerElement>
  )
}
