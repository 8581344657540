import React from "react"
import { Erp as ErpComponent } from "../components/pages/Erp/Erp"
import Layout from "../components/layout"
import { Color, HeroGridTheme } from "../data/style/variables"
import SEO from "../components/seo"

const Cad = () => (
  <Layout navColor={Color.White} borderColor={HeroGridTheme.lineColor}>
    <SEO
      description="Op zoek naar een ERP systeem dat bij u bedrijf past? Wij maken ERP systemen voor de industrie. Onze systemen zijn geschikt voor de kleinere ondernemer en de grote fabrieken."
      title="ERP systemen voor de industrie"
      lang="nl"
    />
    <ErpComponent />
  </Layout>
)

export default Cad
