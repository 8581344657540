import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { Grid } from "../../../../../components/general/Grid/Grid"
import { Section } from "../../../../../components/general/Section/Section"
import { TypographyAnimation } from "../../../../../components/general/TypographyAnimation/TypographyAnimation"
import { Color, GrayGridTheme } from "../../../../../data/style/variables"
import { Bold } from "../../../../general/Bold/Bold"
import {
  Paragraph,
  TitleH2,
  TitleH3,
} from "../../../../general/Typography/Typography"
import { ContainerElement } from "./styled"

export const Investment: React.FC = () => {
  return (
    <ContainerElement>
      <Grid
        gridElementProps={{
          baseHeight: "auto",
          ...GrayGridTheme,
          detailTextColor: Color.DarkGray,
          spaced: true,
        }}
        title={
          <TitleH2 color={Color.Black}>
            Lage investeringskosten en <Bold>snel</Bold> resultaat.
          </TitleH2>
        }
      >
        <Section sectionElementProps={{ noPaddingTop: true, grow: 2 }}>
          <TypographyAnimation>
            <Paragraph color={Color.Black}>
              Onze apps en websites draaien op Android en iOS. Uw medewerkers
              kunnen deze apps gelijk in gebruik nemen. Het gebruik van
              smartphones voor onze apps is een goedkope optie voor uw bedrijf.
              De meeste medewerkers hebben immers al een smartphone.
            </Paragraph>
            <TitleH3 color={Color.Black}>
              Uw huidige data en infrastructuur overzetten.
            </TitleH3>
            <Paragraph color={Color.Black}>
              Het overzetten van uw huidige bestanden en andere data doen we –
              als dat mogelijk is - bij u ter plaatse. U kunt gebruik maken van
              Cloud opslag, lokale opslag (eigen server) of beide. De
              ERP-clients (PC, tablet of smartphone) vragen niet veel
              rekenkracht en meestal is uw eigen IT-infrastructuur al geschikt.
              Wij doen er alles aan om uw investeringskosten zo laag mogelijk te
              houden.
            </Paragraph>
          </TypographyAnimation>
        </Section>
        <Section sectionElementProps={{ noPaddingTop: true }}>
          <TypographyAnimation>
            <TitleH3 color={Color.Black}>
              Disc verzorgt alles van A tot Z.
            </TitleH3>
            <Paragraph color={Color.Black}>
              Hebt u al een bestaand ERP-systeem en wilt u het moderniseren met
              ons ERP of hebt u nog geen ERP-systeem en werkt u bijvoorbeeld nog
              met diverse Excel bestanden, we verzorgen alles van A t/m Z. De
              conversie naar ons ERP (lay-outs), het overzetten van uw bestaande
              data (in welk format dan ook), we gaan volgens ons samen
              opgesteld plan te werk en leveren resultaat.
            </Paragraph>
            <TitleH3 color={Color.Black}>Eenvoudige implementatie.</TitleH3>
            <Paragraph color={Color.Black}>
              Normaal gesproken duurt de conversie – afhankelijk van de
              hoeveelheid data die u al heeft - 1 á 2 dagen. Dit kunnen we ook
              in een weekend of tijdens uw bedrijfssluiting uitvoeren. We doen
              er alles aan om de eventuele hinder – tijdens de overgang - tot
              een minimum te beperken.
            </Paragraph>
          </TypographyAnimation>
        </Section>
      </Grid>
    </ContainerElement>
  )
}
