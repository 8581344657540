import React from "react"
import "typeface-roboto-mono"
import { Footer } from "../Home/sections/Footer/Footer"
import { About } from "./sections/About/About"
import { Departments } from "./sections/Departments/Departments"
import { Hero } from "./sections/Hero/Hero/Hero"
import { Info } from "./sections/Info/Info"
import { Investment } from "./sections/Investment/Investment"
import { OpenPlatform } from "./sections/OpenPlatform/OpenPlatform"
import { ContainerElement } from "./styled"

export const Erp: React.FC = () => {
  return (
    <ContainerElement>
      <Hero />
      <About />
      <Info />
      <Departments />
      <Investment />
      <OpenPlatform />
      <Footer />
    </ContainerElement>
  )
}
