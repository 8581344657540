import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { ProductHero } from "../../../../../general/ProductHero/ProductHero"
import { ContainerElement } from "./styled"

export const Hero: React.FC = ({ ...props }) => {
  const imageData = useStaticQuery(graphql`
    query {
      heroImage: file(relativePath: { eq: "shutterstock_507808582.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <ContainerElement>
      <ProductHero
        title="ERP systemen voor de industrie"
        image={imageData.heroImage.childImageSharp.fluid.src}
        technicalDetails="Desktop - Cloud - Mobiel - Business"
        infoTexts={["Industrie 4.0", "Cloud-based", "Waardevaste investering"]}
      />
    </ContainerElement>
  )
}
