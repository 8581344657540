import React from "react"
import { Grid } from "../../../../../components/general/Grid/Grid"
import { Section } from "../../../../../components/general/Section/Section"
import { TypographyAnimation } from "../../../../../components/general/TypographyAnimation/TypographyAnimation"
import { RoutePath } from "../../../../../data/RoutePath"
import {
  BlackGridTheme,
  Color,
  GrayGridTheme,
} from "../../../../../data/style/variables"
import { ListButton } from "../../../../general/ListButton/ListButton"
import {
  Paragraph,
  TitleH2
} from "../../../../general/Typography/Typography"
import { ContainerElement } from "./styled"

export const OpenPlatform: React.FC = () => {
  return (
    <>
      <ContainerElement>
        <Grid
          gridElementProps={{
            baseHeight: "auto",
            ...BlackGridTheme,
            detailTextColor: Color.DarkGray,
            spaced: true,
            noPaddingBottom: true,
          }}
          title={<TitleH2 color={Color.White}>Open platform.</TitleH2>}
        >
          <Section sectionElementProps={{ noPaddingTop: true, grow: 2 }}>
            <TypographyAnimation>
              <Paragraph color={Color.White}>
                Wij hebben voor meerdere klanten ook selectie tools ontwikkeld
                voor hun producten. Kijk bijvoorbeeld eens op: Dakventilator
                selectie tool Ned Air is een van Nederlands grootste fabrikanten
                van luchtbehandelingsapparatuur en fabriceert o.a.
                luchtbehandelingskasten, schoolventilatie units en
                dakventilatoren voor gebouwen. We hebben voor Ned Air een
                dakventilator selectie tool ontwikkelt. De klant (meestal een
                installateur) kan met deze selectie tool zelf de juiste
                dakventilator selecteren omdat geen enkel gebouw hetzelfde is.
                Met de tool bepaal je bijvoorbeeld de meest energiezuinige
                dakventilator of degene die het minste geluid produceert.
                Vanzelfsprekend is de selectie tool aan ons ERP gekoppeld en
                komt de verkooporder automatisch in het ERP-systeem.
              </Paragraph>
              <Paragraph color={Color.White}>
                Heeft u ook behoefte aan een selectie tool voor uw product. Wij
                kunnen niet wachten om deze samen met u te ontwikkelen. Ga met
                ons ervaren team de uitdaging aan en u hebt gegarandeerd
                resultaat.
              </Paragraph>
            </TypographyAnimation>
          </Section>
          <Section sectionElementProps={{ noPaddingTop: true }}></Section>
        </Grid>
      </ContainerElement>
      <ContainerElement>
        <Grid
          gridElementProps={{
            baseHeight: "auto",
            ...BlackGridTheme,
            detailTextColor: Color.DarkGray,
            spaced: true,
            noPadding: true,
          }}
        >
          <Section sectionElementProps={{ noPaddingTop: true }}>
            <ListButton
              title="3D configurator"
              content="Cloud en on-premise Power Bi oplossingen voor onze applicaties en uw bestaande applicaties."
              path={RoutePath.Cad}
              color={Color.White}
            />
          </Section>
          <Section sectionElementProps={{ noPaddingTop: true }}>
            <ListButton
              title="Power Bi oplossingen"
              content="Cloud en on-premise Power Bi oplossingen voor onze applicaties en uw bestaande applicaties."
              path={RoutePath.PowerBi}
              color={Color.White}
            />
          </Section>
        </Grid>
      </ContainerElement>
      <ContainerElement>
        <Grid
          gridElementProps={{
            baseHeight: "125px",
            ...GrayGridTheme,
            detailTextColor: Color.DarkGray,
            spaced: true,
            noPadding: true,
          }}
        ></Grid>
      </ContainerElement>
    </>
  )
}
