import React from "react"
import { Grid } from "../../../../../components/general/Grid/Grid"
import { Section } from "../../../../../components/general/Section/Section"
import { TypographyAnimation } from "../../../../../components/general/TypographyAnimation/TypographyAnimation"
import { Color, GrayGridTheme } from "../../../../../data/style/variables"
import { Paragraph, TitleH3 } from "../../../../general/Typography/Typography"
import { ContainerElement } from "./styled"

export const Info: React.FC = () => {
  return (
    <ContainerElement>
      <Grid
        gridElementProps={{
          baseHeight: "auto",
          ...GrayGridTheme,
          detailTextColor: Color.DarkGray,
        }}
      >
        <Section>
          <TypographyAnimation>
            <TitleH3 color={Color.Black}>
              Inzicht bieden in huidige voortgang en werkzaamheden.
            </TitleH3>
            <Paragraph color={Color.Black}>
              Omdat we het wiel niet voor een tweede keer willen uitvinden
              gebruiken we Microsoft Dynamics als CRM. Dit bespaard u naast
              tijd ook kosten.
            </Paragraph>
          </TypographyAnimation>
        </Section>
        <Section>
          <TypographyAnimation>
            <TitleH3 color={Color.Black}>
              Verhoogde productiviteit en versnelde rapportages.
            </TitleH3>
            <Paragraph color={Color.Black}>
              Door middel van recepturen kunt u de meest complexe producten
              definiëren in ons ERP-systeem. Rapporten over veel gebruikte
              producten kunt u zelf binnen de applicatie uitdraaien.
            </Paragraph>
          </TypographyAnimation>
        </Section>
        <Section>
          <TypographyAnimation>
            <TitleH3 color={Color.Black}>
              Voorbereid op de toekomst en Industrienorm 4.0.
            </TitleH3>
            <Paragraph color={Color.Black}>
              De ‘smart factory’ waar alles met elkaar verbonden is,
              transformeert op dit moment. Door nu mee te groeien blijft de
              impact beperkt, maar het resultaat evengoed merkbaar.
            </Paragraph>
          </TypographyAnimation>
        </Section>
      </Grid>
    </ContainerElement>
  )
}
